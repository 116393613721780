<template>
  <v-card class="px-2 card mb-6 flat-card" color="surface">
    <v-row class="d-flex align-center">
      <v-col cols="4" class="pl-0 pb-0">
        <div style="width:108px;" @click="productDetail(result_product.id)" class="search-card-img">
          <img
            class="mr-1 search-card-img"
            :src="result_product.image"
            style="width:100%;"
          />
        </div>
      </v-col>
      <v-col @click="productDetail(result_product.id)" class="">
        <div class="title-wrapper">
          <span
            class="medium-font mr-1 onSurface--text"
            v-text="result_product.title.slice(0, 30)"
          ></span
          ><span
            class="medium-font"
            v-if="result_product.title > result_product.title.slice(0, 30)"
            >...</span
          >
        </div>

        <v-row
          v-if="result_product.offer && result_product.onhand != 0"
          class="d-flex align-end justify-end pa-2 mb-1"
        >
          <span
            class="error--text text-decoration-line-through medium-font ml-2"
            v-if="
              result_product.price != result_product.price_gross &&
                result_product.price_gross != 0
            "
            v-text="result_product.price_grossFormat"
          ></span>
          <div class="label-wrapper1 text-center primary ml-3">
            <div class="off-label surface text-center">
              <span
                class="offLabel--text font-weight-bold"
                v-text="result_product.offer"
              ></span>
            </div>
          </div>
        </v-row>
        <div
          v-if="result_product.Is_Bulk == 1 && result_product.onhand != 0"
          class="d-flex align-center justify-end px-2 pb-2 pt-5 cost-container"
        >
          <span class="medium-font ml-auto onSurface--text">هرکیلوگرم:</span>
          <span
            class="medium-font ml-3 onSurface--text"
            v-text="result_product.priceFormat"
          ></span>
        </div>

        <div
          class="d-flex align-center justify-end px-2 pb-2 my-2 cost-container"
        >
          <span class="medium-font ml-auto onSurface--text">قیمت:</span>
          <span
            class="medium-font ml-3 onSurface--text"
            v-if="result_product.Is_Bulk == 1 && result_product.onhand != 0"
            v-text="result_product.price_format_pack"
          ></span>
          <span
            class="medium-font ml-3 onSurface--text"
            v-if="result_product.Is_Bulk != 1 && result_product.onhand != 0"
            v-text="result_product.priceFormat"
          ></span>
          <span
            class="medium-font ml-3 onSurface--text"
            v-if="result_product.onhand == 0"
            v-text="'ناموجود'"
          ></span>
        </div>
      </v-col>
    </v-row>
    <v-card-actions class="px-0">
      <v-spacer></v-spacer>
      <div>
        <v-btn
          @click="open_basket"
          v-if="!open_basket_btn"
          class="button small-button secondaryActions onSecondaryActions--text"
        >
          <v-icon
            v-if="result_product.onhand != 0"
            size="19"
            class="onSecondaryActions--text"
            >mdi-plus</v-icon
          >
          <span
            v-if="result_product.onhand != 0"
            class="onSecondaryActions--text"
            >سبد خرید</span
          >
          <span
            v-if="result_product.onhand == 0"
            class="onSecondaryActions--text"
            >ناموجود</span
          >
        </v-btn>
      </div>
      <div>
        <plus
          @show="show"
          v-if="result_product.Is_Bulk != 1 && open_basket_btn == true"
          :orders="orders"
          :slider="result_product"
        />
      </div>
      <div>
        <overflow
          @show="show"
          color="secondaryActions"
          v-if="result_product.Is_Bulk == 1 && open_basket_btn == true"
          :orders="orders"
          :slider="result_product"
        />
      </div>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
.title-wrapper {
  height: 35px;
  text-align: right;
  width: 100%;
  overflow: hidden;
  min-height: 35px;
  line-height: 25px;
}
.cost-container {
  height: 30px;
  width: 100%;
  overflow: hidden;
  min-height: 25px;
  line-height: 25px;
}
.add-basket {
  position: absolute;
  bottom: 20px;
  right: 8px;
}
.label-wrapper1 {
  border: 2px solid #f0108b;
  overflow: hidden;
  border-radius: 6px;
  margin: 9px 0px 0;
  font-size: 12px;
  width: 34px;
}
</style>
<script>
import { mapState } from "vuex";
import router from "@/router";
import plus from "../../home/components/buttons/selectBtn";
import overflow from "../components/overflowBtn";
export default {
  components: {
    // loading,
    plus,
    overflow,
  },
  props: ["result_product", "show_basket"],
  data: () => ({
    open_basket_btn: false,
    orders: {
      order: {},
      orderd: false,
    },
  }),
  created() {
    this.added_to_basket = parseInt(this.$props.result_product.Min_Order);
    for (let key of this.basket) {
      if (key.id == this.$props.result_product.id) {
        this.orders.orderd = true;
        this.orders.order = key;
        this.addToBasket = this.orders.orderd;
        this.open_basket_btn = true;
        //  this.added_to_basket = parseInt(key.count)
        //  this.count = parseInt(key.count)
        //  this.show_count = true
        // if (this.$props.result_product.Is_Bulk == 1) {
        //   this.added_to_basket = parseFloat(key.count) / parseFloat(this.$props.result_product.weight)
        //   this.count = parseFloat(key.count) / parseFloat(this.$props.result_product.weight)
        // }
      }
    }
  },
  mounted() {
    caches.open("images").then((cache) => {
      cache.add(this.$props.result_product.image);
    });
  },
  computed: {
    ...mapState({
      basket: (state) => state.basket.basket,
      addBasket_status: (state) => state.basket.addBasket_status,
      error_add_basket: (state) => state.basket.addBasket_error,
      loading: (state) => state.search.load_page,
    }),
  },
  watch: {
    // basket: {
    //   handler: function () {
    //     for (let key of this.basket) {
    //       if (key.id == this.$props.result_product.id) {
    //         this.orders.orderd = true
    //         this.orders.order = key
    //         this.addToBasket = this.orders.orderd
    //         this.open_basket_btn = true
    //         //  this.added_to_basket = parseInt(key.count)
    //         //  this.count = parseInt(key.count)
    //         //  this.show_count = true
    //         // if (this.$props.result_product.Is_Bulk == 1) {
    //         //   this.added_to_basket = parseFloat(key.count) / parseFloat(this.$props.result_product.weight)
    //         //   this.count = parseFloat(key.count) / parseFloat(this.$props.result_product.weight)
    //         // }
    //       }
    //     }
    //   }
    // },
    added_to_basket: {
      handler: function() {
        if (this.added_to_basket > 1) {
          this.minus = true;
        } else {
          this.minus = false;
        }
      },
    },
    //   addBasket_status: {
    //   handler: function () {
    //     if (this.addBasket_status == true) {
    //       this.loading = false
    //       if (this.error_add_basket == false) {
    //         this.count = this.added_to_basket
    //       } else {
    //         this.added_to_basket = this.count
    //       }
    //     }
    //   }
    // },
    // bage: {
    //   handler: function () {
    //     if (this.bage == false) {
    //       setTimeout(() => {
    //         this.show = false
    //         if (this.added_to_basket >= this.$props.result_product.Min_Order) {
    //           this.show_count = true
    //         }
    //       }, 2500);
    //     }
    //   }
    // }
  },
  methods: {
    productDetail(productID) {
      router.push(`/product_detail/${productID}`);
    },
    open_basket() {
      this.open_basket_btn = true;
    },
    show(show) {
      this.open_basket_btn = show;
    },
    // add_basket () {
    //   const product = this.$props.result_product
    //   this.bage = true
    //   store.dispatch('alertError', {show: false, type: 'capacity'})
    //   const min = Math.min(product.onhand,product.Max_Order)
    //   if (this.added_to_basket < min) {
    //     this.loading = true
    //     this.added_to_basket = parseInt(this.added_to_basket) + parseInt(product.lot_size)
    //     addTObasket({product: { product_id: product.id ,count: this.added_to_basket, org_id: product.Org_ID },
    //       type: '+',
    //       price: 0
    //     })
    //   } else{
    //     store.dispatch('alertError', {show: true, type: 'capacity'})
    //     setTimeout(() => {
    //       store.dispatch('alertError', {show: false, type: 'capacity'})
    //     }, 2500)
    //   }
    //   setTimeout(() => {
    //     this.bage = false
    //   }, 1000);
    // },
    // remove_basket () {
    //   this.bage = true
    //   const product = this.$props.result_product
    //   if (this.added_to_basket > product.Min_Order) {
    //     this.loading = true
    //     this.added_to_basket = parseInt(this.added_to_basket) - parseInt(product.lot_size)
    //     setTimeout(() => {
    //       addTObasket({product: { product_id: product.id ,count: this.added_to_basket, org_id: product.Org_ID },
    //      type: '-',
    //      price: 0
    //      })
    //     }, 700)
    //   }
    //   setTimeout(() => {
    //     this.bage = false
    //   }, 1000);
    // },
    // cansel_basket () {
    //   this.show = false
    //   this.show_count = false
    //   removeTObasket({ product_id: this.$props.result_product.id, org_id: this.$props.result_product.Org_ID })
    //   this.added_to_basket = parseInt(this.added_to_basket) - 1
    //   this.count = parseInt(this.count) - 1
    //   this.bage == false
    // }
  },
};
</script>
